import React from 'react';
import './terms-condition.css';

const TermsAndConditions = () => {
    return (
        <div className="container terms-container">
            <h2>Terms and Conditions</h2>
            <p>
                Welcome to Harsafe Meet. By accessing or using our app, you agree to abide by the following terms and conditions. Please read them carefully.
            </p>

            <h3>1. Acceptance of Terms</h3>
            <p>
                By using this app, you confirm your agreement to be bound by these terms and conditions. If you disagree with any part of the terms, you may not use the app.
            </p>

            <h3>2. Use of Services</h3>
            <p>
                You agree to use the services provided by Harsafe Meet only for lawful purposes and in a manner that does not infringe the rights of or restrict or inhibit anyone else’s use of the app.
            </p>

            <h3>3. Privacy Policy</h3>
            <p>
                Our Privacy Policy outlines how we handle your personal data. By using this app, you agree to the collection and use of information in accordance with our Privacy Policy.
            </p>

            <h3>4. Limitation of Liability</h3>
            <p>
                Harsafe Meet shall not be liable for any damages arising out of your use of the app or inability to access or use the services.
            </p>

            <h3>5. Modifications to Terms</h3>
            <p>
                We reserve the right to modify these terms at any time. Your continued use of the app following any changes indicates your acceptance of the new terms.
            </p>

            <h3>6. User Responsibilities</h3>
            <p>
                You are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account. You agree to notify us immediately of any unauthorized use of your account.
            </p>

            <h3>7. Termination</h3>
            <p>
                We reserve the right to terminate or suspend your account and access to the app without prior notice or liability, for any reason whatsoever, including, without limitation, if you breach the Terms.
            </p>

            <h3>8. Indemnification</h3>
            <p>
                You agree to indemnify, defend, and hold harmless Harsafe Meet and its affiliates, partners, and employees from any claims, losses, liabilities, damages, costs, or expenses (including reasonable attorney's fees) arising from your use of the app or violation of these terms.
            </p>

            <h3>9. Governing Law</h3>
            <p>
                These Terms and Conditions shall be governed by and construed in accordance with the laws of [Your Country/State]. Any disputes arising out of or related to these terms shall be subject to the exclusive jurisdiction of the courts in [Your Location].
            </p>

            <h3>10. Contact Us</h3>
            <p>
                If you have any questions about these Terms and Conditions, please contact us at:
                <br />
                <strong>Email:</strong> support@harsafemeet.com
            </p>

            <p className="terms-footer">
                If you have any questions about these Terms and Conditions, please contact us.
            </p>
        </div>
    );
};

export default TermsAndConditions;
