import React from 'react'
import '../greatfeatures/greatfeatures.css'
import HomeChat from '../../images/home-chat.png'
import { FaRegCheckCircle } from "react-icons/fa";

const greatfeatures = () => {
    return (

        <section className="bg-white has-svg features pb-0" id="features">
            {/* <!-- <svg xmlns="http://www.w3.org/2000/svg" version="1.1" className="separator separator-top" width="100%" height="100"
        viewBox="0 0 100 101" preserveAspectRatio="none">
        <path d="M0 100 L100 0 L100 100 Z"></path>
    </svg> --> */}
            <div className="container">
                <div className="row">
                    <div className="col-lg-7 col-md-7 grtfetrs">
                        <div className="same-heading-ewallet">
                            <h2>Great <strong>Features</strong> Of Harsafe Meet</h2>
                            <p>Simple. Secure. Free.</p>
                        </div>
                        {/* <!-- <h3 className="black f-s60 f-w500">Great features</h3>
                        <h2 className="grey text-uppercase f-s36 f-w300">Simple. Secure. Free.</h2> --> */}
                        <ul className="green-list hidden-xs">
                            <li><i className="fa fa-check-circle-o fa-fw fa-lg fa-2x m-r-5 sr-icons hidden-xs"
                                aria-hidden="true"><FaRegCheckCircle /></i>High-definition video and crystal-clear audio for seamless communication.</li>
                            <li><i className="fa fa-check-circle-o fa-fw fa-lg fa-2x m-r-5 sr-icons hidden-xs"
                                aria-hidden="true"><FaRegCheckCircle /></i>Robust security to ensure your conversations and data remain private.</li>
                            <li><i className="fa fa-check-circle-o fa-fw fa-lg fa-2x m-r-5 sr-icons hidden-xs"
                                aria-hidden="true"><FaRegCheckCircle /></i>Share screens and documents instantly, enhancing productivity during meetings.</li>
                            <li><i className="fa fa-check-circle-o fa-fw fa-lg fa-2x m-r-5 sr-icons hidden-xs"
                                aria-hidden="true"><FaRegCheckCircle /></i>Built-in messaging to complement voice and video calls, facilitating efficient multitasking.</li>
                            <li><i className="fa fa-check-circle-o fa-fw fa-lg fa-2x m-r-5 sr-icons hidden-xs"
                                aria-hidden="true"><FaRegCheckCircle /></i>Enable transactions directly within conversations, streamlining the sales process.</li>
                        </ul>
                        <p className="grey f-s16 m-t-20 m-b-40 f-w300">Harsafe Meet delivers a powerful virtual meeting experience with HD video and audio calls, ensuring crystal-clear communication. It features end-to-end encryption for maximum security, real-time collaboration with screen sharing, and integrated chat for efficient multitasking. Additionally, users can record and replay meetings, and facilitate transactions directly within conversations, streamlining the sales process and enhancing productivity.</p>
                        <a href="#screenshot" className="btn btn-primary btn-lg page-scroll f-s16 f-w700 sr-button">Download Now!</a>
                    </div>
                    <div className="col-lg-5 col-md-5 m-t-20">
                        <img src={HomeChat} alt="Hello"
                            className="img-responsive center-block img-hei" />
                    </div>
                </div>
            </div>
        </section>
        // <!-- /features -->

    )
}

export default greatfeatures