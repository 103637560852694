import React from 'react'
import './services.css'
import ChatApp from '../../images/chat-app.svg'
import ECommerce from '../../images/e-commerce.svg'
import HisabBook from '../../images/hisab-book.svg'
import Expences from '../../images/expences.svg'
import UPIS from '../../images/upi.svg'
import Reels from '../../images/reel.svg'

const services = () => {
    return (

            <section className="same-specing new-ewallet-service-wrapper social-media-tech-section" id='services'>
                <div className="container">
                    <div className="same-heading-ewallet">
                        <h2><strong>Services</strong> For a Better Experience </h2>
                        <p>Harsafe Meet combines all essential services in one powerful app: seamless HD video and audio calls, real-time chat, secure transactions, comprehensive expense management, and intuitive financial tracking. Experience unparalleled communication, robust security, and efficient management tools, all integrated into a single, user-friendly platform.</p>
                    </div>
                    <div className="row">
                        <div className="col-xs-12 col-sm-6 col-md-4 mb-3 ghj">
                            <div className="servicesnbenefitsbox pickcolorwrapper" data-color="#F5E6FE">
                                <figure className="pickcolor">
                                    <img className="lozad" src={ChatApp} alt="Harsafe Meet Chat App" data-loaded="true" />
                                </figure>
                                <figcaption className='position-relative'>
                                    <h3 className="serviceboxtitle">Harsafe Meet Chat App</h3>
                                    <p>Harsafe Meet Chat App offers seamless communication with HD video and audio, secure end-to-end encryption, real-time collaboration, and integrated chat. Experience top-quality interactions, privacy, and efficiency in every conversation.</p>
                                </figcaption>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-6 col-md-4 mb-3 ghj">
                            <div className="servicesnbenefitsbox pickcolorwrapper" data-color="#F5E6FE">
                                <figure className="pickcolor">
                                    <img className="lozad" src={Reels} alt="Harsafe Meet Reel App" data-loaded="true" />
                                </figure>
                                <figcaption className='position-relative'>
                                    <h3 className="serviceboxtitle">Harsafe Meet Reel App</h3>
                                    <p>Harsafe Meet Reel App lets you create, share, and explore engaging short videos effortlessly. With intuitive editing tools, seamless integration, and a vibrant community, capture and enjoy moments in a dynamic and creative way.</p>
                                </figcaption>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-6 col-md-4 mb-3 ghj">
                            <div className="servicesnbenefitsbox pickcolorwrapper" data-color="#E3F8FA">
                                <figure className="pickcolor">
                                    <img className="lozad" src={HisabBook} alt="Harsafe Meet Hisab Book App"
                                        data-loaded="true" />
                                </figure>
                                <figcaption className='position-relative'>
                                    <h3 className="serviceboxtitle">Harsafe Meet Hisab Book App</h3>
                                    <p>Harsafe Meet Hisab Book App simplifies financial tracking with real-time updates, secure data management, and intuitive reporting. Effortlessly manage accounts, track expenses, and ensure accurate bookkeeping with our comprehensive solution.</p>
                                </figcaption>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-6 col-md-4 mb-3 ghj">
                            <div className="servicesnbenefitsbox pickcolorwrapper" data-color="#FFE6E2">
                                <figure className="pickcolor">
                                    <img className="lozad" src={ECommerce} alt="Harsafe Meet E-Commerce App"
                                        data-loaded="true" />
                                </figure>
                                <figcaption className='position-relative'>
                                    <div className="coming-soon-ribbon">Coming Soon</div>
                                    <h3 className="serviceboxtitle">Harsafe Meet E-Commerce App</h3>
                                    <p>Harsafe Meet E-Commerce App integrates secure transactions with real-time communication. Enjoy a seamless shopping experience with HD video support, secure payments, and real-time customer service, all within one platform.</p>
                                </figcaption>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-6 col-md-4 mb-3 ghj">
                            <div className="servicesnbenefitsbox pickcolorwrapper" data-color="#FEEFD7">
                                <figure className="pickcolor">
                                    <img className="lozad" src={Expences} alt="Harsafe Meet Expenses Management App" data-loaded="true" />
                                </figure>
                                <figcaption className='position-relative'>
                                    <div className="coming-soon-ribbon">Coming Soon</div>
                                    <h3 className="serviceboxtitle">Harsafe Meet Expenses Management App</h3>
                                    <p>Harsafe Meet Expenses Management App streamlines expense tracking with real-time updates, secure data storage, and intuitive reporting. Easily manage, categorize, and analyze expenses to maintain financial clarity and control.</p>
                                </figcaption>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-6 col-md-4 mb-3 ghj">
                            <div className="servicesnbenefitsbox pickcolorwrapper" data-color="#FFE2F8">
                                <figure className="pickcolor">
                                    <img className="lozad" src={UPIS}
                                        alt="Harsafe Meet UPI Payment App" data-loaded="true" />
                                </figure>
                                <figcaption className='position-relative'>
                                    <div className="coming-soon-ribbon">Coming Soon</div>
                                    <h3 className="serviceboxtitle">Harsafe Meet UPI Payment App</h3>
                                    <p>Harsafe Meet UPI Payment App offers a secure, fast, and convenient way to handle transactions. Seamlessly integrate UPI payments with real-time communication, ensuring smooth financial operations and effortless money transfers directly within the app.</p>
                                </figcaption>
                            </div>
                        </div>
                    </div>
                </div>
            </section>



    )
}

export default services