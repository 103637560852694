// import React from 'react'
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
// import { Link } from 'react-scroll';
import '../navbar/navbar.css'
import WhiteLogo from '../../images/logo_white.png'

const Navbar = () => {

    const [isScrolled, setIsScrolled] = useState(false);

    const handleScroll = () => {
        if (window.scrollY > 100) {
            setIsScrolled(true);
        } else {
            setIsScrolled(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const [isCollapsed, setIsActive] = useState(false);

    // Function to toggle the class
    const toggleClass = () => {
        setIsActive(!isCollapsed);  // Toggle the state between true and false
    };

    const scrollToSection = (id) => {
        const section = document.getElementById(id);
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
        }
    };


    return (
        // { isScrolled? 'header scrolled': 'header' }
        <nav id="mainNav" className={isScrolled ? 'navbar navbar-default navbar-fixed-top p-0 affix' : 'navbar navbar-default navbar-fixed-top p-0 affix-top'}>
            <div className="container-fluid d-block">
                <div className="navbar-header">
                    <button type="button" className="navbar-toggle collapsed" data-toggle="collapse"
                        data-target="#navbar-collapse" onClick={toggleClass} aria-expanded={isCollapsed}>
                        <span className="sr-only">Toggle navigation</span>
                        Menu <i className="fa fa-bars"></i>
                    </button>
                    <Link className="navbar-brand page-scroll" to="/#page-top">
                        <img src={WhiteLogo} alt="SIT" className="img-responsive center-block h-100" />
                    </Link>
                </div>

                <div className={isCollapsed ? 'collapse navbar-collapse in' : 'collapse navbar-collapse'} id="navbar-collapse">
                    <ul className="nav navbar-nav navbar-right">
                        <li>
                            <Link to="/" onClick={() => scrollToSection('home')}>Home</Link>
                        </li>
                        <li>
                            <Link to="/" onClick={() => scrollToSection('services')}>Services</Link>
                        </li>
                        <li>
                            <Link to="/" onClick={() => scrollToSection('features')}>Features</Link>
                        </li>
                        <li>
                            <Link to="/" onClick={() => scrollToSection('download')}>Download</Link>
                        </li>
                        <li>
                            <Link to="/" onClick={() => scrollToSection('contact')}>Contact</Link>
                        </li>

                        {/* <li>
                            <Link className="page-scroll" to="/#home" smooth={true}>Home</Link>
                        </li>
                        <li>
                            <Link className="page-scroll" to="/#services" smooth={true}>Services</Link>
                        </li>
                        <li>
                            <Link className="page-scroll" to="/#features" smooth={true}>Features</Link>
                        </li>
                        <li>
                            <Link className="page-scroll" to="/#download" smooth={true}>Download</Link>
                        </li>
                        <li>
                            <Link className="page-scroll" to="/#contact" smooth={true}>Contact</Link>
                        </li> */}
                    </ul>
                </div>
            </div>
        </nav>


    )
}

export default Navbar