import React from 'react'
import '../form/form.css'

const form = () => {
    return (

        // <!-- <div className="grey-line"></div> -->
        <section id="contact" className="bg-white">
            <div className="container m-b-70">
                <div className="row">
                    <div className="col-lg-8 col-md-8 m-auto float-none">
                        <h3 className="black f-s60 f-w500 text-center">Contact us</h3>
                        <p className="grey text-uppercase f-s24 f-w300 text-center">Ask more informations</p>
                        <form className="contactus m-t-40 text-left">
                            <label>Name</label>
                            <input type="text" name="name" className="form-control m-b-20" />
                            <label>Email</label>
                            <input type="text" name="email" className="form-control m-b-20" />
                            <label>Message</label>
                            <textarea className="form-control m-b-20"></textarea>
                            <button className="btn btn-primary btn-lg page-scroll f-s18 sr-button" type="submit">Send</button>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default form