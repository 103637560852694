import React from 'react'
import '../cutomerrating/cutomerrating.css'
import GDO from './../../images/gdo.png'

const cutomerrating = () => {
    return (

        <section className="inbox-container ">
            <div className="container text-center">
                <div className="row justify-content-center align-items-cente">
                    <div className="col-lg-6">
                        <div className="inbox-card">
                            <img src={GDO} alt="Hello" className="img-responsive center-block img-hei mw-400 w-100" />
                        </div>
                    </div>
                    <div className="col-lg-6 text-start mt-5 mt-lg-0">
                        <h2 className="text-left black f-s40 f-w700">Get direct orders from your customers</h2>
                        <p className="f-s16 text-left">Create custom landing pages with Rareblocks that converts more visitors than any
                            website. With lots of unique blocks easily build a page. There are many variations of passages
                            of available.</p>
                        <div className="d-flex align-items-center mt-4">
                            <div className="mr-5">
                                <span className="metrics">4.3K+</span>
                                <p className="f-s14">Website's Powering</p>
                            </div>
                            <div>
                                <span className="metrics">7M+</span>
                                <p className="f-s14">Chats in Last 2023</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>


    )
}

export default cutomerrating