import React from 'react'
import logo from '../images/logo-purple.png';
// import './bootstrap.min.css';
// import './App.css';
import Navbar from "../section/navbar/navbar";
import Hero from "../section/hero/hero";
import Services from "../section/services/services";
import WhyChooseUs from "../section/whychooseus/whychooseus";
import GreatFeatures from "../section/greatfeatures/greatfeatures";
import Conversations from "../section/conversations/conversations";
import MeetCustomers from "../section/meetcustomers/meetcustomers";
import DownloadNow from "../section/downloadnow/downloadnow";
import CutomerRating from "../section/cutomerrating/cutomerrating";
import Form from "../section/form/form";
import Footer from "../section/footer/footer";

function Home() {
  let name = "SIT"
  return (

    <div className="App">
      {/* <Navbar /> */}
      <Hero />
      <Services />
      <WhyChooseUs />
      <GreatFeatures />
      <Conversations />
      <MeetCustomers />
      <DownloadNow />
      <CutomerRating />
      <Form />
      {/* <Footer /> */}
      {/* <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <p>{name}</p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>

      </header> */}
    </div>
  );
}

export default Home;
