import React from 'react'
import '../hero/hero.css'
import { FaApple } from "react-icons/fa";
import { IoLogoGooglePlaystore } from "react-icons/io5";
import Heromage from '../../images/smiling-african-american-woman-using-mobile-phone-shopping-online-reading-text-message 1.png'

const hero = () => {
    return (

        <header id='home'>
            <div className="containr">
                <div className="header-content row">
                    <div className="col-lg-7 col-md-7">
                        <img src={Heromage} alt="Hello" className="img-responsive center-block mockup-header effect2 w-75" />
                    </div>
                    <div className="col-lg-5 col-md-5 m-b-20 header-content-inner">
                        <h1 id="homeHeading" className="effect1">Speak freely<br /> with your friends</h1>
                        <h2 className="f-w300 m-t-20 effect2">Secure messaging and calling for free</h2>
                        <a href="#" className="btn btn-primary btn-lg page-scroll f-s18 m-r-10 m-l-10 m-b-10 effect1">
                            <i className="fa fa-apple fa-lg fa-2x m-r-10" aria-hidden="true"><FaApple /></i>
                            App Store</a>
                        <a href="#" className="btn btn-primary btn-lg page-scroll f-s18 m-b-10 effect1"><i
                            className="fa fa-play fa-lg fa-2x m-r-10" aria-hidden="true"><IoLogoGooglePlaystore /></i>Google Play</a>
                    </div>
                </div>
            </div>
        </header>
        // <!-- /header -->


    )
}

export default hero