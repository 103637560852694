import React from 'react'
import '../downloadnow/downloadnow.css'
import { FaApple } from "react-icons/fa";
import { IoLogoGooglePlaystore } from "react-icons/io5";

const downloadnow = () => {
    return (


        <section class="bg-light-grey" id="download">
            <div class="container">
                <div class="row m-b-40">
                    <div class="col-lg-12 col-md-12 m-t-70 m-b-70 text-center">
                        <h3 class="white f-s60 f-w500">Download now. It's free</h3>
                        <p class="white f-s18 m-t-40 m-b-40 f-w300">Download <b>Harsafe Meets</b> for free and enjoy secure, high-quality virtual meetings. With HD video, real-time collaboration, and robust encryption, it's the perfect platform for businesses and professionals. Get started now!</p>
                        <a href="#" class="btn btn-primary btn-lg page-scroll f-s18 m-r-10 sr-button"><i
                            class="fa fa-apple fa-lg fa-2x m-r-10" aria-hidden="true"><FaApple /></i>App Store</a>
                        <a href="#" class="btn btn-primary btn-lg page-scroll f-s18 sr-button"><i
                            class="fa fa-play fa-lg fa-2x m-r-10" aria-hidden="true"><IoLogoGooglePlaystore /></i>Google Play</a>
                    </div>
                </div>
            </div>
        </section >

        // <!-- / download-- >

    )
}

export default downloadnow